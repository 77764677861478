/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore
// @ts-nocheck

import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import { ColorRing } from "react-loader-spinner";
import STRINGS from "../pages/Strings";
import { useEffect } from "react";
import authStore from "../Keys";
import { getCall } from "../Api/services";

function SetCookieForDewdrops() {
  console.log(window.location.href);
  const url = window.location.href;
  const locationToRedirect = url.substring(url.indexOf("&") + 1);
  const aadObjectId = url.split("&")[0].split("?")[1];
  console.log(locationToRedirect,aadObjectId);
  const [, setCookie] = useCookies(["SAAS_COMMON_BASE_TOKEN_ID"]);
  let zauthToken;
  useEffect(async() => {
  zauthToken = await getToken(aadObjectId);
  zauthToken =  zauthToken ===undefined ?aadObjectId:zauthToken
    console.log("zauthToken",zauthToken)
    let name = "SAAS_COMMON_BASE_TOKEN_ID",
      value = zauthToken,
      pathe = "/",
      domain = ".zycus.com",
      //domain = "localhost",
      ATS_getExpire = function () {
        return "ATS_getExpire";
      };

    setCookie("SAAS_COMMON_BASE_TOKEN_ID", value, {
      path: pathe,
      domain: domain,
      sameSite: "none",
      httpOnly: false,
      secure: true,
    });
    console.log("setcookie executed");
    let curCookie =
      name +
      "=" +
      zauthToken +
      ", expires=" +
      ATS_getExpire() +
      ",path=" +
      pathe +
      ", domain=" +
      domain;
    Cookies.set("session", curCookie, {
      domain: ".zycus.com",
      sameSite: "none",
      httpOnly: false,
      secure: true,
    });
    console.log("cookie", window.document.cookie);
    document.cookie = curCookie;
    window.document.cookie = `SAAS_COMMON_BASE_TOKEN_ID=${zauthToken}`;
    console.log("cookie", window.document.cookie);
    window.location.assign(locationToRedirect);
  }, [locationToRedirect, setCookie, zauthToken]);
  return (
    <div>
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#7B83EB", "#505AC9", " #464EB8", "#7B83EB", "#7B83EB"]}
      />
      <p>{STRINGS.HANGON}</p>
    </div>
  );
}

const getToken = async(userObjectId)=>{
  const authObject = new authStore();
  const config = {
    method: "get",
    url:
      `https://${authObject.accountName}.table.core.windows.net/${authObject.tableName}(PartitionKey=%27` +
      userObjectId +
      "%27,RowKey=%27" +
      userObjectId +
      "%27)" +
      authObject.token,
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await getCall(config.url, "", config.headers);
  let zauthToken;
  if (response !== undefined && response !== null) {
     zauthToken = response?.data?.zycusAuthToken;
  }
  return zauthToken;
}

export default SetCookieForDewdrops;
