import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import Collaboration from "../pages/Collaboration";
import "../index.css";
import { ProgressBar } from "react-loader-spinner";
import { app } from "@microsoft/teams-js";
import * as eventBus from '@dewdrops/event-bus';
import authStore from "../Keys";
import { getCall } from "../Api/services";

try {
  app.initialize();
} catch (err) { }
function TestCollab() {
  const [callback, setCallBack] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);
  const [poNumber, setPoNumber] = useState("");
  const [typeOfDoc, setTypeOfDoc] = useState("");
  const [myContext, setMyContext] = useState({})
  // useEffect(()=>{
  //    app.getContext().then((context) => {
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //    let ponumberfromSubPage = context?.page?.subPageId.split("|");
  //    console.log("ponumberfromSubPage",ponumberfromSubPage);
  //    const poNumber =ponumberfromSubPage[0]
  //    const typeOfDocstatic = ponumberfromSubPage[1]
  //    console.log("ponumber",poNumber)
  //    console.log("typeOfDocstatic",typeOfDocstatic)

  //    if(poNumber && typeOfDocstatic)
  //    {
  //     setPoNumber(poNumber)
  //     setTypeOfDoc(typeOfDocstatic)
  //     setMyContext(context)
  //    }
  //   });
  // },[])

  const initializeEventBus = (args) => {
    const { poNumber, typeOfDocstatic, context, zycusUserId, emailAddress, langCode } = args;
    console.log(poNumber+typeOfDocstatic+zycusUserId+emailAddress+langCode);
    eventBus.init({
      merlinDetails: () => {
        return {
          poId: poNumber,
          product: typeOfDocstatic,
          aadObjectId: context?.user?.id,
          region:process.env.REACT_APP_bot
        }
      },
      getUserDetails: () => {
        return {
          userDetails: {
            userId: zycusUserId,
            emailId: emailAddress,
            locale: langCode
          },
          userPreference: {
            locale: langCode
          }
        }
      }

    }).then((res) => {
      console.log("Response", res)
      setCallBack(true);
    }).catch((err) => {
      console.log("Error", err)
      setCallBack(true);
    })
  }


  const getAzureEntity = async () => {
    let ponumberfromSubPage;
    let poNumber;
    let typeOfDocstatic;
    console.log("getAzureEntity");
    app.getContext().then(async(context) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ponumberfromSubPage = context?.page?.subPageId.split("|");
      console.log("ponumberfromSubPage", ponumberfromSubPage);
      poNumber = ponumberfromSubPage[0]
      typeOfDocstatic = ponumberfromSubPage[1]
      console.log("ponumber", poNumber)
      console.log("typeOfDocstatic", typeOfDocstatic)
      console.log("accountname", process.env.accountName);
      const authObject = new authStore();
      let config = {
        method: "get",
        url:
          `https://${authObject.accountName}.table.core.windows.net/${authObject.tableName}(PartitionKey=%27` +
          context?.user?.id +
          "%27,RowKey=%27" +
          context?.user?.id +
          "%27)" +
          authObject.token,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await getCall(config.url, "", config.headers);
      if (response !== undefined && response !== null) {
        const { zycusUserId, emailAddress, langCode } = response?.data;
        const args = {
          poNumber: poNumber,
          typeOfDocstatic: typeOfDoc,
          zycusUserId,
          emailAddress,
          langCode,
          context
        }
        initializeEventBus(args)
      }

    });
   
  };
  const disablePopupImg = require("../images/popups.png"); // with require
  const msteamsIcon = require("../images/msteamsicon.png");

  useEffect(() => {
    try {
      microsoftTeams.app.initialize().then(() => {
        microsoftTeams.authentication
          .authenticate({
            url: `${window.location.origin}/collabAssign`,
            isExternal: false,
            width: 600,
            height: 535,
          })
          .then((result) => {
            setLoadingImg(false);
            console.log("Login succeeded: " + result);
          })
          .catch(async () => {
            setLoadingImg(false);
            console.log("window close");
            await getAzureEntity();

          });
      });
      //  }
    } catch (err) { }
  }, []);

  return (
    <div>
      {loadingImg && (
        <div className="parent">
          <img
            className="msteamsIcon"
            src={msteamsIcon}
            alt="Microsoft teams"
          />
          <div>
            {loadingImg ? (
              <span className="progressBar">
                <ProgressBar
                  height="50"
                  width="50"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{ width: "180px" }}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#7B83EB"
                  barColor="#464EB8"
                />
                <p style={{ margin: 0, fontSize: 12, marginLeft: 280 }}>
                  Loading App .....
                </p>
              </span>
            ) : null}
          </div>
          <img className="disablePopupImg" src={disablePopupImg} alt="Popup" />
        </div>
      )}
      {!loadingImg && callback && <Collaboration poNumber={poNumber} typeOfDoc={typeOfDoc} />}
    </div>
  );
}

export default TestCollab;
